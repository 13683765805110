import React, { useRef, useLayoutEffect, useImperativeHandle, forwardRef } from "react";
import { Map, View } from "ol";
import { XYZ, Vector as VectorSource, ImageStatic } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer, Image } from "ol/layer";
import {  Fill, Text, Style } from "ol/style";
import { Modify } from 'ol/interaction.js'
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { setLayerSource, mapViewConfiguration } from '../../components/utils'
import '../../components/style.css'
import { Projection, transform } from "ol/proj";

const ZoneMap = forwardRef(({ }, ref) => {  
  const mapRef = useRef()
  const divRef = useRef()

  useLayoutEffect(() => {
    const labelSource = new VectorSource({ })

    const labelLayer = new VectorLayer({
      source: labelSource,
      style: function (feature) {
        return new Style({
          text: new Text({
            font: '14px sans-serif',
            text: feature.get("zone_name"),
            fill: new Fill({
              color: "white"
            }),
            backgroundFill: new Fill({
              color: "grey"
            }),
            padding: [3, 3, 3, 3]
          })
        })
      }
    })

    labelLayer.set('name', 'labelLayer')
    labelLayer.setZIndex(99)

    const view = new View({
      center: mapViewConfiguration["center"],
      extent: mapViewConfiguration["extent"],
      zoom: 0,
      showFullExtent: true
    })

    const map = new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
          })
        }),
        labelLayer
      ],
      view: view
    })

    mapRef.current = {
      "map": map,
      "labelSource": labelSource,
      "labelLayer": labelLayer,
      "interaction": null
    }
  }, [])

  useImperativeHandle(ref, () => ({
    zoneUpdate: (data) => {
      setLayerSource(
        mapRef.current.labelLayer, 
        {
          "type": "FeatureCollection",
          "features": data.map((i) => ({
            "type": "Feature",
            "id": i["zone_id"],
            "geometry":{
              "type": "Point","coordinates":[i["marker_lon"], i["marker_lat"]]
            },
            "properties":{
              "zone_name": i["zone_name"],
              "zone_description": i["zone_description"],
            }
          }))
        }
      )
    },
    addFloorPlan: (blob, coordinate) => {
      mapRef.current.map.addLayer(new Image({
        source: new ImageStatic({
          url: URL.createObjectURL(blob),
          projection: new Projection({
            code: 'xkcd-image',
            units: 'pixels',
            extent: [coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]]
          }),
          imageExtent: [coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]]
        })
      }))
    },
    setModifing: (state) => {
      if (state === true) {
        const modify = new Modify({source: mapRef.current.labelSource});

        mapRef.current.map.addInteraction(modify);
        mapRef.current.interaction = modify
      }
      else {
        mapRef.current.map.removeInteraction(mapRef.current.interaction)
      }
    },
    getZones: () => {
      const map = mapRef.current.map
      return map.getLayers().getArray().find(layer => layer.get('name') == 'labelLayer').getSource().getFeatures()
    },
    setMapExtent: (coordinate) => {
      mapRef.current.map.getView().fit([coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]], mapRef.current.map.getSize())
    }
  }))

  return (<>
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} ref={divRef}>
      <div style={{ height: "100%", width: "100%" }} id="map" />
    </div>
  </>)
})

export default ZoneMap
