import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { IoAddCircleOutline } from "react-icons/io5";
import moment from 'moment';

import useAuth from 'Shared/Hooks/useAuth';
import useFetchData from 'Shared/Hooks/useFetchData';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import DataTable from 'Shared/Components/DataTable';
import PopupModal from 'Shared/Components/PopupModal';
import { apiUrl } from 'Shared/utils';

const PermitToWorkList = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()
  const navigate = useNavigate()
  const { role } = useAuth()

  const [formTemplates, setFormTemplates] = useState([])
  const [forms, setForms] = useState([])

  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getFormTemplates = async () => {
      const body = await send({
        method: "GET",
        url: `${apiUrl}/permit_to_work/templates`,
        returnType: "json"
      })

      setFormTemplates(body)
    }

    const getForms = async () => {
      const getStatus = (status) => {
        switch (status) {
          case -3:
            return "Expired"
          case -2:
            return "Approved"
          case -1:
            return "Active"
        }
      }

      const body = await send({
        method: "GET",
        url: `${apiUrl}/permit_to_work/forms`,
        returnType: "json"
      })

      setForms(body.map((i) => ({
        "id": i["form_id"],
        "template_id": i["template_id"],
        "template_name": i["template_name"],
        "worker_name": i["worker_name"],
        "status": (i["status"] >= 0) ? `Pending for ${i["step_name"]}` : getStatus(i["status"]),
        "start_time": moment(i["permit_start_time"]).format("YYYY-MM-DD HH:mm"),
        "end_time": moment(i["permit_end_time"]).format("YYYY-MM-DD HH:mm"),
      })))

      setLoading(false)

    }

    getFormTemplates()
    getForms()
  }, [])

  const onClick = (e, row) => {
    const form = forms.filter((i) => i["id"] == row["id"])[0]
    navigate(`./${form["template_id"]}/${form["id"]}`)
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={forms} count={null}
              onRowClick={onClick}
              columns={{
                "template_name": { "label": t("ptw_templateName"), "sorting": false, "nowrap": true },
                "worker_name": { "label": t("ptw_workerName"), "sorting": false, "nowrap": true },
                "start_time": { "label": t("ptw_startTime"), "sorting": false, "nowrap": true },
                "end_time": { "label": t("ptw_endTime"), "sorting": false, "nowrap": true },
                "status": { "label": t("ptw_status"), "sorting": false, "nowrap": true },
              }}
              appActions={() => { return [
                (["admin"].includes(role)) && {icon: <IoAddCircleOutline size={20}/>, text: t("general_add"), onClick: function() { setModalOpen(true) }}
              ]}}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <PopupModal isOpen={modalOpen} maxHeight="50%" width="70%" toggle={() => setModalOpen(false)}>
      <List style={{ padding: "0px" }}>
        {(formTemplates).map((i) => 
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={i["template_name"]} secondary={i["description"]} onClick={() => navigate(`./${i["template_id"]}`)} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </PopupModal>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default PermitToWorkList