import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Menu, Stack, Typography } from '@mui/material';
import { Button, ButtonGroup, ListGroup } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react';
import { Form } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-hk'

import { MdLanguage } from "react-icons/md";
import { IoSettingsSharp, IoRainy } from "react-icons/io5";
import { FaTemperatureFull } from "react-icons/fa6";
import { IoIosLogOut, IoIosWater } from "react-icons/io";
import { PiList } from "react-icons/pi";
import { GoArrowSwitch } from "react-icons/go";
import { MdOutlineChangeCircle } from "react-icons/md";

import { IconButtonComponent } from 'Shared/Components/Icon';
import PopupModal from 'Shared/Components/PopupModal';
import { PasswordInput, customizeRequiredMark } from 'Shared/Components/FormComponents'

import useLoop from 'Shared/Hooks/useLoop';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl, webUrl } from 'Shared/utils';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { showError, showSuccess } from 'Shared/Components/NotifyToast';
import useAuth from 'Shared/Hooks/useAuth';
import { RiAdminFill } from 'react-icons/ri';

const Appbar = ({ projects, userMetaData, projectInfo, setAppbarLoading }) => {
  const { user, loginWithRedirect, logout } = useAuth0()
  const { organization, project } = useParams()
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const { role } = useAuth()

  const [weatherInfo, setWeatherInfo] = useState()
  const [weatherWarning, setWeatherWarning] = useState()
  const [time, setTime] = useState()
  const [icon, setIcon] = useState()
  const [favicon, setFavicon] = useState()
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);

  const [projectModelOpen, setProjectModelOpen] = useState(false)
  const [languageModelOpen, setLanguageModelOpen] = useState(false)
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false)

  const getWeather = async () => {
    await Promise.all([
      send({ url: `${apiUrl}/weather/info`, method: "GET", returnType: "json" }).then(res =>
        res && setWeatherInfo(res)
      ),
      send({ url: `${apiUrl}/weather/warning`, method: "GET", returnType: "json" }).then(res =>
        res && setWeatherWarning(res)
      )
    ]);
  }

  const getTime = () => {
    setTime(moment(new Date()))
  }

  const getIcon = async () => {
    await Promise.all([
      send({ url: `${apiUrl}/common/icon/${organization}`, method: "GET", returnType: "blob" }).then(res =>
        res && setIcon(URL.createObjectURL(res))
      ),
      send({ url: `${apiUrl}/common/favicon/${organization}`, method: "GET", returnType: "blob" }).then(res =>
        res && setFavicon(URL.createObjectURL(res))
      )
    ]);
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        getWeather(),
        getTime(),
        getIcon(),
      ]);

      setAppbarLoading(false)
    }

    init()
  }, [])

  useLoop(getTime, 1000)
  useLoop(getWeather, 600000)

  const selectProject = (project) => {
    setProjectModelOpen(false)
    window.location.href = `${webUrl}/portal/${organization}/${project}`
  }

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    setLanguageModelOpen(false)
    window.location.reload()
  }

  const onFinish = async (values) => {
    setLoading(true)

    if (values["newPassword"] !== values["confirmNewPassword"]) {
      showError(t("signup_passwordNotMatch"))
      return
    }

    const res = await send({
      method: "POST",
      url: `${apiUrl}/common/change_password`,
      contentType: "json",
      body: {
        "email": user.email,
        "original_password": values["originalPassword"],
        "new_password": values["newPassword"]
      }
    })

    if (res != null) {
      setChangePasswordModalOpen(false)
      showSuccess(t("general_success"))
      form.resetFields()
    }

    setLoading(false)
  }

  return (<>
    <Stack justifyContent="space-between" direction="row" spacing={5} style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ display: { xs: 'none', lg: 'flex' }, height: "100%", justifyContent: "center", alignItems: "center" }}>
          <img src={icon} style={{ height: "80%", cursor: "pointer" }} onClick={() => navigate(".")}/>
        </Box>

        <Box sx={{ display: { xs: 'flex', lg: 'none' }, height: "100%", justifyContent: "center", alignItems: "center" }}>
          <img src={favicon} style={{ height: "80%", cursor: "pointer" }} onClick={() => navigate(".")}/>
        </Box>
      </div>

      {(project && projectInfo) &&
        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }} variant="body1">
            {projectInfo[i18n.language.toLowerCase()]}
          </Typography>
        </div>
      }

      <Stack justifyContent="space-between" direction="row" spacing={2} style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ height: "100%", display: { xs: 'none', md: 'flex' }, flexDirection: 'column' , justifyContent: "center", alignItems: "center" }}>
          <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {time &&
              <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>{time.locale(i18n.language.toLowerCase()).format("LLLL")}</div>
            }
          </div>

          <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Stack justifyContent="space-between" direction="row" spacing={2} style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
              {weatherInfo && <>
                <img src={weatherInfo["icon"]} style={{ height: "20px"}} />
                  
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                  <FaTemperatureFull size={20} /> {weatherInfo["temperature"]}°C
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                  <IoRainy size={20} /> {weatherInfo["rainfall"]}mm
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                  <IoIosWater size={20} /> {weatherInfo["humidity"]}%
                </div>
              </>}

              {weatherWarning && <>
                {weatherWarning.map((i) => (
                  <img src={i} style={{ height: "20px"}} />
                ))}
              </>}
            </Stack>
          </div>
        </Box>

        <IconButtonComponent title={t("general_project")} onClick={() => setProjectModelOpen(true)}>
          <PiList size={20}/>
        </IconButtonComponent>

        {(role === "admin") &&
          <IconButtonComponent title={t("module_adminConsole")} onClick={() => navigate(`/admin_console/${organization}`)}>
            <RiAdminFill size={20}/>
          </IconButtonComponent>
        }

        <IconButtonComponent title={t("general_settings")} onClick={(event) => setAnchorEl(event.currentTarget)}>
          <IoSettingsSharp size={20}/>
        </IconButtonComponent>
      </Stack>
    </Stack>

    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ horizontal: 'right' }} MenuListProps={{ sx: { py: 0 } }}>
      <List style={{ padding: "0px" }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={user.picture} />
          </ListItemAvatar>
          <ListItemText
            primary={userMetaData ? userMetaData["display_name"] : ""}
            secondary={<Typography sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{user.email}</Typography>}
          />
        </ListItem>

        <Divider sx={{ bgcolor: "secondary.light" }}/>    

        <ListItemButton onClick={() => { setLanguageModelOpen(true); setAnchorEl(null); }}>
          <ListItemIcon>
            <MdLanguage size={20} />
          </ListItemIcon>
          <ListItemText primary={t("general_language")}/>
        </ListItemButton>

        <ListItemButton onClick={() => { loginWithRedirect({ appState: { returnTo: webUrl } }); }}>
          <ListItemIcon>
            <GoArrowSwitch size={20}/>
          </ListItemIcon>
          <ListItemText primary={t("general_switchOrginization")}/>
        </ListItemButton>

        <ListItemButton onClick={() => { setChangePasswordModalOpen(true) }}>
          <ListItemIcon>
            <MdOutlineChangeCircle size={20}/>
          </ListItemIcon>
          <ListItemText primary={t("general_changePassword")}/>
        </ListItemButton>

        <ListItemButton onClick={() => { logout({ logoutParams: { returnTo: webUrl } }); }}>
          <ListItemIcon>
            <IoIosLogOut size={20}/>
          </ListItemIcon>
          <ListItemText primary={t("general_logout")}/>
        </ListItemButton>
      </List>          
    </Menu>

    <PopupModal maxHeight="80%" width="80%" isOpen={projectModelOpen} close toggle={() => setProjectModelOpen(false)}>
      <ListGroup variant="flush">
        {(projects ?? []).map((i) => 
          <ListGroup.Item className="border-0" action onClick={() => selectProject(`${i["project_code"]}`)} >
            {`${i[i18n.language.toLowerCase()]}`} 
          </ListGroup.Item>
        )}
      </ListGroup>
    </PopupModal>

    <PopupModal maxHeight="80%" width="80%" isOpen={languageModelOpen} close toggle={() => setLanguageModelOpen(false)}>
      <ListGroup variant="flush">
        {(["en", "zh"]).map((i) => 
          <ListGroup.Item className="border-0" action onClick={() => changeLanguage(i)}>
            {t(`language_${i}`)}
          </ListGroup.Item>
        )}
      </ListGroup>
    </PopupModal>

    <PopupModal maxHeight="80%" width="80%" isOpen={changePasswordModalOpen} close toggle={() => setChangePasswordModalOpen(false)}>
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>
        <PasswordInput field_name={t("general_originalPassword")} field_id="originalPassword" required/>
        <PasswordInput field_name={t("general_newPassword")} field_id="newPassword" required/>
        <PasswordInput field_name={t("general_confirmNewPassword")} field_id="confirmNewPassword" required/>
      
        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>

    <LoadingScreen isopen={loading} />
  </>)
}

export default Appbar
