import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Form } from 'antd';
import { IoAddCircleOutline } from "react-icons/io5";
import moment from 'moment';

import useCommonAPI from 'Shared/API/Common';

import useFetchData from 'Shared/Hooks/useFetchData';
import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import { apiUrl } from 'Shared/utils';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { DatetimePicker, SelectField, TextInput, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useAuth from 'Shared/Hooks/useAuth';

const VRTrainingRecord = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()
  const [form] = Form.useForm();
  const { role } = useAuth()

  const [traingRecord, setTraingRecord] = useState([])
  const [workers, setWorkers] = useState([])
  const [formOpen, setFormOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const tableRef = useRef()

  const { getWorkers } = useCommonAPI()

  const getTrainingRecord = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/vr_training/list`,
      returnType: "json"
    })

    body && setTraingRecord(body)
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        getTrainingRecord()
      ]);

      setLoading(false)
    }

    init()
  }, [])

  const toggleForm = async () => {
    if (!formOpen) {
      setLoading (true)

      const w = await getWorkers()
      setWorkers(w)

      await getWorkers()

      setLoading (false)
    }
    else{
      form.resetFields()
      
    }
    
    setFormOpen(!formOpen)
  }

  const onFinish = async (values) => {
    setLoading(true)

    const res = await send({
      method: "POST",
      url: `${apiUrl}/vr_training/record`,
      contentType: "json",
      body: {
        "worker_id": values["worker_id"],
        "training_module": values["training_module"],
        "training_date": moment(new Date(values["training_date"])).unix(),
      }
    })

    if (res != null) {
      toggleForm()
      showSuccess(t("general_success"))

      tableRef.current.setLoading(true)

      await getTrainingRecord()

      tableRef.current.setLoading(false)
    }

    setLoading(false)
  }

  return (<>
    <Container fluid className="mainContainer">
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={traingRecord} count={null} ref={tableRef}
              columns={{
                "training_date": { "label": t("vrTraining_trainingDate"), "sorting": false, "nowrap": true },
                "worker_name": { "label": t("vrTraining_workerName"), "sorting": false, "nowrap": true },
                "training_module": { "label": t("vrTraining_trainingModule"), "sorting": false, "nowrap": true },
              }}
              appActions={() => { return [
                (["pm", "admin"].includes(role)) && {icon: <IoAddCircleOutline size={20}/>, text: t("general_add"), onClick: function() { toggleForm() }}
              ]}}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <PopupModal isOpen={formOpen} close toggle={() => toggleForm()} width="80%">
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ "worker_name": "" }} style={{ width: "100%", padding: "10px" }}>
        <SelectField field_name={t("vrTraining_workerName")} field_id="worker_id" required props={{ "type": "dropdown", "options": workers.map((i) => ({ "value": i["worker_id"], "label": i["worker_name"] })) }}/>
        <DatetimePicker field_name={t("vrTraining_trainingDate")} field_id="training_date" required props={{ "type": "date", "max":  new Date() }} />
        <TextInput field_name={t("vrTraining_trainingModule")} field_id="training_module" required />

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>
    
    <LoadingScreen isOpen={loading} />
  </>)
}

export default VRTrainingRecord
