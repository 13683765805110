import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import Appbar from './components/Appbar';
import DrawerMenu  from './components/DrawerMenu';

import LoadingScreen from 'Shared/Components/LoadingScreen';
import useWindowSize from 'Shared/Hooks/useWindowSize';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';

const AdminConsole = () => {
  const { user } = useAuth0()
  const { organization } = useParams()
  const { send } = useFetchData()
  const windowSize = useWindowSize()
  const navigate = useNavigate()

  const drawerWidth = { "xs": 80, "lg": 280 }
  const appbarHeight = 60
  const [collapse, setCollapse] = useState()

  const [userMetaData, setUserMetaData] = useState()
  const [loading, setLoading] = useState(true);
  const [appbarLoading, setAppbarLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setLoading(true)

      await Promise.all([
        send({ url: `${apiUrl}/common/user_metadata`, method: "GET", returnType: "json" }).then(res =>{
          if (res) {
            setUserMetaData(res["user_metadata"])
          } 
        })
      ]);

      setLoading(false)
    }

    if (organization == null) {
      navigate(`/portal/${user.org_name}`)
    }

    init()
  }, [])

  useEffect(() => {
    if (windowSize["width"] < 960) {
      setCollapse(true)
    }
    else {
      setCollapse(false)
    }
  }, [windowSize])

  return (<>
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      {organization && <>
        <Box sx={{ height: appbarHeight, backgroundColor: "white", display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", overflow: "hidden", borderBottom: "solid 0.5px grey" }}>
          <Appbar appbarHeight={appbarHeight} userMetaData={userMetaData} setAppbarLoading={setAppbarLoading}/>
        </Box>

        <Box sx={{ height: windowSize["height"]-appbarHeight, backgroundColor: "white" }}>
          <Box sx={{ display: 'flex'}}>
            <Box sx={{ width: collapse ? drawerWidth["xs"] : drawerWidth["lg"]}}>
              <DrawerMenu collapse={collapse} setCollapse={setCollapse} appbarHeight={appbarHeight} drawerWidth={drawerWidth} />
            </Box>

            <Box sx={{ width: collapse ? windowSize["width"]-drawerWidth["xs"] : windowSize["width"]-drawerWidth["lg"], height: windowSize["height"]-appbarHeight, overflow: "auto", backgroundColor: "#F2F2F2", padding: "15px" }}>
              <Outlet/>
            </Box>
          </Box>
        </Box>
      </>}
    </Box>

    <LoadingScreen isOpen={loading || appbarLoading} />
  </>)
}

export default AdminConsole