import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Container, Col, Row } from 'react-bootstrap'

import useFetchData from 'Shared/Hooks/useFetchData';
import DataTable from 'Shared/Components/DataTable';
import { apiUrl } from 'Shared/utils';
import LoadingScreen from 'Shared/Components/LoadingScreen';

const AssetList = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()
  
  const [equipments, setEquipments] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getEquipments = async () => {
      const body = await send({
        method: "GET",
        url: `${apiUrl}/assets_tracking/equipments`,
        returnType: "json"
      })

      body && setEquipments(body)
    }

    const init = async () => {
      await Promise.all([
          getEquipments()
      ]);

      setLoading(false)
    }

    init()
  }, [])

  return (<>
    <Container fluid className="mainContainer">
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={equipments} count={null}
              columns={{
                "equipment_name": { "label": t("assetsTracking_equipmentName"), "sorting": false, "nowrap": true },
                "equipment_type": { "label": t("assetsTracking_equipmentType"), "sorting": false, "nowrap": true },
                "description": { "label": t("assetsTracking_equipmentDescription"), "sorting": false, "nowrap": true },
              }}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 
    <LoadingScreen isOpen={loading} />
  </>)
}

export default AssetList