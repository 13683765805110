import React from 'react'

import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';

const useCommonAPI = () => {
  const { send } = useFetchData()

  const getWorkers = async (params) => {
    params = params ?? {}

    const body = await send({
      method: "GET",
      url: `${apiUrl}/common/workers`,
      params: {
        "search": params["search"] ?? ""
      },
      returnType: "json"
    })

    return body
  }

  return { getWorkers };
}

export default useCommonAPI;
