import React from 'react'
import { useParams } from 'react-router-dom'

import { jwtDecode } from 'Shared/utils'

const useAuth = () => {
  const { organization, project } = useParams()

  const profile = jwtDecode()

  let role = null

  if (profile["permissions"].includes(profile["org_name"])) {
    role =  "admin"
  }
  else {
    role = (!organization || !project) ? null : profile['permissions'].filter((i) => i.split(':')[1] == project)[0].split(':')[2]
  }

  return { profile, role };
}

export default useAuth
