import React, { useState } from "react";
import { DatePicker, TimePicker, Input, Select } from "antd";
import dayjs from 'dayjs';

import './style.css'

const FloatingLabel = ({ label, value, children }) => {
  const [focus, setFocus] = useState(false);

  const labelClass = focus || (value && value.length !== 0) ? "label label-float" : "label";

  return (
    <div className="float-label floating" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  )
}

export const FloatingDatePicker = ({ label, value, onChange }) => {
  return (
    <FloatingLabel label={label} value={value} className="styles">
      <DatePicker placeholder=""
        value={value ? dayjs(value) : null} onChange={(e) => onChange(e)} maxDate={dayjs(new Date())}
        style={{ width: "100%", padding: "16px 10px 4px 11px", height: "48px" }} popupStyle={{ zIndex: 1500 }} showToday={false}
      />
    </FloatingLabel>
  )
}

export const FloatingTimeRangePicker = ({ label, value, onChange }) => {
  return (
    <FloatingLabel label={label} value={value}>
      <TimePicker.RangePicker
        value={value ? [dayjs(value[0]), dayjs(value[1])]: null} onChange={(e) => onChange(e)} format="HH:mm" placeholder=""
        style={{ width: "100%", padding: "16px 10px 4px 11px", height: "48px" }} popupStyle={{ zIndex: 1500 }} showToday={false}
      />
    </FloatingLabel>
  )
}

export const FloatingDropdown = ({ label, value, options, onChange }) => {    
  return (
    <FloatingLabel label={label} value={value} className="styles">
      <Select
        value={value} onChange={(e) => onChange(e)} options={options}
        style={{ width: "100%", height: "48px" }} dropdownStyle={{ zIndex: 1500 }}
      />
    </FloatingLabel>
  )
}

export const FloatingInputDisabled = ({ label, value }) => {    
  return (
    <FloatingLabel label={label} value={value}>
      <Input value={value} disabled style={{ width: "100%", padding: "16px 10px 4px 11px", height: "48px" }} />
    </FloatingLabel>
  )
}
