import React from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom'
import { Box, Divider, Stack } from '@mui/material';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { FaAngleDoubleLeft, FaAngleDoubleRight, FaUsers } from "react-icons/fa";
import { GrProjects } from "react-icons/gr";

import useWindowSize from 'Shared/Hooks/useWindowSize';

const MenuTab = ({ open, setOpen, collapse, icon, route, label, subMenu }) => {
  const { organization } = useParams()

  const onMenuClick = () => {
    if (open === route) {
      setOpen()
    }
    else {
      setOpen(route)
    }
  }

  return (<>
    {subMenu ? (
      <SubMenu style={{ justifyContent: "center" }} label={!collapse && label} icon={icon} open={open === route} onOpenChange={onMenuClick}>
        {subMenu.map((i) => <>
          {(i != null) && 
            <MenuItem style={collapse ? { zIndex: 1000 } : { marginLeft: "10px", marginRight: "10px", backgroundColor: "#DCDCDC" }} component={<Link to={`/admin_console/${organization}/${route}/${i["key"]}`}/>} onClick={() => setOpen()}>
              {i["text"]} 
            </MenuItem> 
          }
        </>)}
      </SubMenu>
    ) : (
      <MenuItem  style={{ justifyContent: "center" }} icon={icon} component={<Link to={`/admin_console/${organization}/${route}`}/>}>
        {!collapse && label}
      </MenuItem>
    )}
  </>)
}

const DrawerMenu = ({ collapse, setCollapse, appbarHeight, drawerWidth }) => {
  const { t } = useTranslation()
  const windowSize = useWindowSize()

  return (<>
    <Box sx={{ display: 'block' }}>        
      <Stack justifyContent="space-between" direction="column" spacing={0} style={{ height: windowSize["height"]-appbarHeight }}>
        <Box style={{ flexGrow: 1, overflow: "auto" }} className="scollbar">
          <Sidebar collapsed={collapse} width={drawerWidth["lg"]} collapsedWidth={drawerWidth["xs"]}>
            <Menu closeOnClick style={{ backgroundColor: "white" }}>
              <MenuTab route="users" icon={<FaUsers size={25} />} label={t("module_users")}/>
              <MenuTab route="projects" icon={<GrProjects size={25} />} label={t("module_projects")}/>
            </Menu>
          </Sidebar>
        </Box>

        <Divider sx={{ bgcolor: "secondary.light" }}/>

        <Box>
          <Sidebar collapsed={collapse} width={drawerWidth["lg"]} collapsedWidth={drawerWidth["xs"]}>
            <Menu style={{ backgroundColor: "white" }}>
              {collapse ? (
                <MenuItem icon={<FaAngleDoubleRight />} onClick={() => setCollapse(false)} />
              ) : (
                <MenuItem icon={<FaAngleDoubleLeft />} onClick={() => setCollapse(true)} />
              )}
            </Menu>
          </Sidebar>
        </Box>
      </Stack>
    </Box>
  </>)
}

export default DrawerMenu
