import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Form } from 'antd';
import { IoAddCircleOutline } from "react-icons/io5";

import useCommonAPI from 'Shared/API/Common';

import useFetchData from 'Shared/Hooks/useFetchData';
import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { TextInput, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import { apiUrl } from 'Shared/utils';
import useAuth from 'Shared/Hooks/useAuth';

const Workers = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()
  const [form] = Form.useForm();
  const { role } = useAuth()

  const [workers, setWorkers] = useState([])
  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState(false);

  const tableRef = useRef()

  const { getWorkers } = useCommonAPI()

  const filterRef = useRef({
    "search": ""
  })

  const loadData = async () => {
    const [workers] = await Promise.all([
      getWorkers({ search: filterRef.current.search })
    ]);

    workers && setWorkers(workers)

    setLoading(false)
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }

    init()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)

    const res = await send({
      method: "POST",
      url: `${apiUrl}/common/workers`,
      contentType: "json",
      body: {
        "firstname": values["firstname"],
        "lastname": values["lastname"],
        "company": values["company"],
        "title": values["title"]
      }
    })

    if (res != null) {
      toggleForm()
      showSuccess(t("general_success"))

      tableRef.current.setLoading(true)

      await loadData()

      tableRef.current.setLoading(false)
    }

    setLoading(false)
  }

  const toggleForm = () => {
    if (formOpen) {
      form.resetFields()
    }
    
    setFormOpen(!formOpen)
  }

  const onSearch = async (value) => {        
    filterRef.current.search = value

    tableRef.current.setLoading(true)

    await loadData()

    tableRef.current.setLoading(false)
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={workers} count={null} ref={tableRef} onSearch={onSearch} searchPlaceHolder={t("worker_search")}
              columns={{
                "worker_name": { "label": t("workers_workerName"), "sorting": false, "nowrap": true },
                "title": { "label": t("workers_title"), "sorting": false, "nowrap": true },
                "company": { "label": t("workers_company"), "sorting": false, "nowrap": true },
              }}
              appActions={() => { return [
                (["pm", "admin"].includes(role)) && {icon: <IoAddCircleOutline size={20}/>, text: t("general_add"), onClick: function() { toggleForm() }}
              ]}}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 
    
    <LoadingScreen isOpen={loading} />

    <PopupModal isOpen={formOpen} close toggle={() => toggleForm()} width="80%">
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ "worker_name": "" }} style={{ width: "100%", padding: "10px" }}>
        <TextInput field_name={t("workers_workerFirstname")} field_id="firstname" required/>
        <TextInput field_name={t("workers_workerLastName")} field_id="lastname" required/>
        <TextInput field_name={t("workers_company")} field_id="company"/>
        <TextInput field_name={t("workers_title")} field_id="title"/>

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>
  </>)
}

export default Workers
