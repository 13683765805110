import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Container, Col, Row, ButtonGroup, Button } from 'react-bootstrap'

import useFetchData from 'Shared/Hooks/useFetchData'
import { apiUrl } from 'Shared/utils'
import DataTable from 'Shared/Components/DataTable';
import useAuth from 'Shared/Hooks/useAuth';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { IoAddCircleOutline } from 'react-icons/io5';
import PopupModal from 'Shared/Components/PopupModal';
import { Form } from 'antd';
import { SelectField, TextInput, customizeRequiredMark } from 'Shared/Components/FormComponents';
import { showSuccess } from 'Shared/Components/NotifyToast';

const ProjectUsers = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()
  const [form] = Form.useForm();

  const [users, setUsers] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const tableRef = useRef()

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/user/project_users`,
      returnType: "json"
    })

    setUsers(body.map((i) => ({
      "user_id": i["user_id"],
      "username": i["username"],
      "email": i["email"],
      "role": t(`users_role_${i["role"]}`)
    })))
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }

    init()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)

    const res = await send({
      method: "POST",
      url: `${apiUrl}/user/add_user_to_project`,
      contentType: "json",
      body: {
        "email": values["email"],
        "role": values["role"],
      }
    })

    if (res != null) {
      toggleForm()
      
      showSuccess(t("general_success"))

      tableRef.current.setLoading(true)

      await loadData()

      tableRef.current.setLoading(false)
    }

    setLoading(false)
  }

  const toggleForm = () => {
    if (modalOpen) {
      form.resetFields()
    }

    setModalOpen(!modalOpen)
  }

  return (<>
    <Container fluid className="mainContainer">
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={users} count={null} ref={tableRef}
              columns={{
                "username": { "label": t("users_username"), "sorting": false, "nowrap": true },
                "email": { "label": t("users_email"), "sorting": false, "nowrap": true },
                "role": { "label": t("users_role"), "sorting": false, "nowrap": true },  
              }}
              appActions={() => { return [
                {icon: <IoAddCircleOutline size={20}/>, text: t("general_add"), onClick: function() { toggleForm() }}
              ]}}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <PopupModal isOpen={modalOpen} maxHeight="50%" width="70%" toggle={toggleForm}>
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>      
        <TextInput field_name={t("users_email")} field_id="email" required />
        <SelectField field_name={t("users_role")} field_id="role" props={{"type": "dropdown", "options": [
          {"value": "pm", "label": "Project Manager"},
          {"value": "so", "label": "Safty Officer"},
          {"value": "client", "label": "Client" }
        ]}} required={true} />

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>

    

    <LoadingScreen isOpen={loading} />
  </>)
}

export default ProjectUsers
