import React from 'react'
import { ResponsiveContainer, Line, YAxis, XAxis, Tooltip, Legend, ComposedChart, Area } from 'recharts'
import moment from 'moment'

const Tick = ({ x, y, stroke, payload }) => {
  const dt_moment = moment(payload.value)

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">{dt_moment.format('HH')}</text>
      {(dt_moment.format('HH') == "00") &&
        <text x={0} y={0} dy={32} textAnchor="middle" fill="#666">{dt_moment.format('YYYY-MM-DD')}</text>
      }
    </g>
  );
}

const TimeSeriesPlot2 = ({ data, threshold_key, items, day, hour, yLeft={}, yRight={} }) => {
  const t0 = moment(day).startOf('day').toDate()

  return (
    data &&
      <ResponsiveContainer width='100%' height='100%'>
        <ComposedChart data={data}  margin={{ top: 0, left: 0, right: 40, bottom: 0 }}>
          {(threshold_key).map((i) => 
            <Area type="monotone" dataKey={i} fill="#ff3399" stroke="#ff3399" strokeWidth={0} legendType="none" tooltipType="none" dot={false} activeDot={false} />
          )}

          {(items).map((i) => 
            <Line type="monotone" dataKey={i["dataKey"]} name={i["name"]} stroke={i["stroke"]} dot={false} />
          )}

          <YAxis domain={yLeft["range"]} label={{ value: yLeft["label"], angle: -90, position: 'insideLeft' }}/>
          <XAxis type='number' dataKey='time' name='Time' allowDataOverflow={true} domain = {[moment(new Date(t0)).add(hour[0], 'hours').toDate(), moment(new Date(t0)).add(hour[1], 'hours').toDate()]} interval={0} tick={<Tick hour={hour}/>} ticks={Array.from({ length: hour[1] - hour[0] + 1 }, (_, i) => hour[0] + i).map((i) => moment(new Date(t0)).add(i, 'hours').toDate().getTime())} />  

          {(data.length > 2) && 
            <Tooltip labelFormatter={t =>  moment(t).format('YYYY-MM-DD HH:mm:SS')} />
          }
          <Legend />
        </ComposedChart>
      </ResponsiveContainer>
  );
}

export default TimeSeriesPlot2
