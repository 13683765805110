import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Container, Col, Row } from 'react-bootstrap'

import useFetchData from 'Shared/Hooks/useFetchData';
import DataTable from 'Shared/Components/DataTable';
import { apiUrl } from 'Shared/utils';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import moment from 'moment';

const GasData = () => {
    const { t } = useTranslation()
    const { send } = useFetchData()
    const navigate = useNavigate()

    const [gasData, setGasData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const getLatestData = async () => {
        const body = await send({
          method: "GET",
          url: `${apiUrl}/gas/data`,
          returnType: "json"
        })

        body && setGasData(body.map((i) => ({
          "device_id": i["device_id"],
          "device_name": i["device_name"],
          "h2s": i["h2s"],
          "co": i["co"],
          "co2": i["co2"],
          "o2": i["o2"],
          "ch4": i["ch4"],
          "pm25": i["pm25"],
          "temperature": i["temperature"],
          "combustible_gas": i["combustible_gas"],
          "last_update": i["last_update"] != null ? moment(i["last_update"]).format("YYYY-MM-DD HH:mm:SS") : null
        })))
      }

      const init = async () => {
        await Promise.all([
          getLatestData()
        ]);

        setLoading(false)
      }

      init()
    }, [])
    
    return (<>
      <Container fluid className="mainContainer">
        <Row style={{ height: "100%" }}>
          <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
            <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
              <DataTable
                data={gasData} count={null}
                onRowClick={(e, row) => navigate(`./${row["device_id"]}`)}
                columns={{
                  "device_name": { "label": t("gas_name"), "sorting": false, "nowrap": true },
                  "h2s": { "label": t("gas_h2s"), "sorting": false, "nowrap": true },
                  "co": { "label": t("gas_co"), "sorting": false, "nowrap": true },
                  "co2": { "label": t("gas_co2"), "sorting": false, "nowrap": true },
                  "o2": { "label": t("gas_o2"), "sorting": false, "nowrap": true },
                  "ch4": { "label": t("gas_ch4"), "sorting": false, "nowrap": true },
                  "pm25": { "label": t("gas_pm25"), "sorting": false, "nowrap": true },
                  "temperature": { "label": t("gas_temperature"), "sorting": false, "nowrap": true },
                  "combustible_gas": { "label": t("gas_combustibleGas"), "sorting": false, "nowrap": true },
                  "last_update": { "label": t("gas_lastUpdate"), "sorting": false, "nowrap": true },
                }}
              />
            </Paper>
          </Col>
        </Row>
      </Container>

      <LoadingScreen isOpen={loading} />
    </>)
}

export default GasData