import React, { useEffect, useState } from 'react'
import { Box, Tabs, Tab } from '@mui/material';

import ConfinedSpaceDashboard from './ConfinedSpace/ConfinedSpaceDashboard';
import AlertSummary from './AlertSummary/AlertSummary';
import { useOutletContext } from 'react-router-dom';

const Dashboard = () => {
  const [currentTab, setCurrentTab] = useState("alert");
  const { projectInfo } = useOutletContext()

  return (<>
    <div className="mainContainer">
      <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
        <Tab label="Alert Summary" value="alert"/>

        {(projectInfo["modules"] .filter((i) => 
          (i["module"] === "confined_space_smartwatch" && i["enabled"] === 1) ||
          (i["module"] === "gas" && i["enabled"] === 1)
        ).length > 0) &&
          <Tab label="Confined Space" value="confined_space"/>
        }
      </Tabs>

      {currentTab === "alert" && 
        <Box style={{ flexGrow: 1, overflow: "auto" }} className='scollbar'>
          <AlertSummary />
        </Box>
      }

      {currentTab === "confined_space" && 
        <Box style={{ flexGrow: 1, overflow: "auto" }} className='scollbar'>
          <ConfinedSpaceDashboard />
        </Box>
      }
    </div>
  </>)
}

export default Dashboard
