import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Container, Col, Row } from 'react-bootstrap'
import { FaUnlock } from "react-icons/fa6";

import useFetchData from 'Shared/Hooks/useFetchData';
import DataTable from 'Shared/Components/DataTable';
import useAuth from 'Shared/Hooks/useAuth';

const SmartlockList = () => {
  const { t } = useTranslation()
  const { role } = useAuth()

  const data = [
    {
      "lock_name": "Lock 01",
      "battery_level": "90",
      "status": "Locked"
    }
  ]
  
  return (
    <Container fluid className="mainContainer">
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={data} count={null}
              columns={{
                "lock_name": { "label": t("smartlock_lockName"), "sorting": false, "nowrap": true },
                "battery_level": { "label": t("smartlock_batteryLevel"), "sorting": false, "nowrap": true },
                "status": { "label": t("smartlock_status"), "sorting": false, "nowrap": true },
              }}
              renderActions={(row) => { return [
                (["pm", "admin"].includes(role)) && {icon: <FaUnlock size={20}/>, text: "Unlock", onClick: function(row) { }},
              ]}}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 
  )
}

export default SmartlockList
