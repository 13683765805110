import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Container, Col, Row } from 'react-bootstrap'

import useFetchData from 'Shared/Hooks/useFetchData'
import { apiUrl } from 'Shared/utils'
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';

const Projects = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()

  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/user/projects`,
      returnType: "json"
    })

    setProjects(body)
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }

    init()
  }, [])

  return (<>
    <Container fluid className="mainContainer">
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={projects} count={null}
              columns={{
                "project_code": { "label": t("admin_projects_projectCode"), "sorting": false, "nowrap": true },
                "project_name_en": { "label": t("admin_projects_projectNameEn"), "sorting": false, "nowrap": true },
                "project_name_zh": { "label": t("admin_projects_projectNameZh"), "sorting": false, "nowrap": true },
              }}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <LoadingScreen isOpen={loading} />
  </>)
}

export default Projects
